import React, { useState, useEffect } from 'react';
import * as fal from '@fal-ai/serverless-client';
import { ClipLoader } from 'react-spinners';
import { auth, googleProvider, getUserCredits, updateCredits } from './firebase'; // Import Firebase
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth'; // Firebase auth methods
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './assets/logo.png'; // Logo import
import SubscriptionAndPurchase from './SubscriptionAndPurchase'; // Subscription Component
import PricingPage from './PricingPage'; // Pricing Page
import SuccessPage from './SuccessPage'; // Import SuccessPage component
import CancelPage from './CancelPage'; // Import CancelPage component

function App() {
    const [humanImageFile, setHumanImageFile] = useState(null);
    const [garmentImageFile, setGarmentImageFile] = useState(null);
    const [humanImagePreview, setHumanImagePreview] = useState('');
    const [garmentImagePreview, setGarmentImagePreview] = useState('');
    const [clothType, setClothType] = useState('Upper');
    const [imageSize, setImageSize] = useState('Default'); // New Option
    const [inferenceSteps, setInferenceSteps] = useState(30); // New Option
    const [guidanceScale, setGuidanceScale] = useState(2.5); // New Option
    const [seed, setSeed] = useState('random'); // New Option
    const [outputImage, setOutputImage] = useState('');
    const [status, setStatus] = useState('idle');
    const [progress, setProgress] = useState('');
    const [credits, setCredits] = useState(0);
    const [extraCredits, setExtraCredits] = useState(0); // Track extra credits
    const [user, setUser] = useState(null);
    const [showBuyCreditsPopup, setShowBuyCreditsPopup] = useState(false);
    const [isHovered, setIsHovered] = useState(false); // Hover state for the login button
    const [isRunning, setIsRunning] = useState(false); // Track 'Run' button loading state

    fal.config({
        credentials: "d2024fd6-545e-4e01-b4d5-967bf267c57b:f0c5a676465a6a514250548ad14c0ea7", // Replace with your FAL-AI API key
    });

    // Set up listener to detect auth state changes and restore user session
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser); // Restore the user session
            } else {
                setUser(null); // User is signed out
            }
        });

        return () => unsubscribe(); // Cleanup the listener
    }, []);

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            setUser(result.user); // Set user state with Google user details
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleGoogleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleFileUpload = (event, setImageFile, setImagePreview) => {
        const file = event.target.files[0];
        setImageFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImagePreview(reader.result);
        };
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    useEffect(() => {
        if (user) {
            const fetchCredits = async () => {
                try {
                    const { credits, extraCredits } = await getUserCredits(user.uid); // Get from Firebase
                    setCredits(credits);
                    setExtraCredits(extraCredits);
                } catch (error) {
                    console.error('Error fetching credits:', error);
                }
            };
            fetchCredits();
        }
    }, [user]);

    const handleSubmit = async () => {
        if (credits <= 0 && extraCredits <= 0) {
            setShowBuyCreditsPopup(true);
            return;
        }

        setStatus('loading');
        setIsRunning(true); // Set loading on the 'Run' button
        setProgress('Image processing started...');
        try {
            const humanImageBase64 = await convertToBase64(humanImageFile);
            const garmentImageBase64 = await convertToBase64(garmentImageFile);

            const result = await fal.subscribe('fal-ai/cat-vton', {
                input: {
                    human_image_url: humanImageBase64,
                    garment_image_url: garmentImageBase64,
                    cloth_type: clothType,
                    imageSize, // Pass additional parameters
                    inferenceSteps,
                    guidanceScale,
                    seed,
                },
                logs: true,
                onQueueUpdate: (update) => {
                    if (update.status === 'IN_PROGRESS') {
                        const progressLogs = update.logs.map((log) => log.message).join('\n');
                        setProgress(progressLogs);
                    }
                },
            });

            setOutputImage(result.image.url);
            setStatus('success');
            setProgress('Image processing completed.');

            if (credits > 0) {
                setCredits(credits - 1); // Deduct 1 credit
                await updateCredits(user.uid, credits - 1, extraCredits);
            } else if (extraCredits > 0) {
                setExtraCredits(extraCredits - 1); // Deduct from extra credits
                await updateCredits(user.uid, credits, extraCredits - 1);
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus('error');
            setProgress('An error occurred during processing.');
        } finally {
            setIsRunning(false); // Stop loading on the 'Run' button
        }
    };

    // Define image preview styles (fixed size)
    const imagePreviewStyle = {
        width: "150px",
        height: "200px",
        objectFit: "cover",
    };

    if (!user) {
        // Login screen with updated design
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-900 to-purple-900">
                <div className="bg-white bg-opacity-10 p-8 rounded-2xl shadow-2xl backdrop-blur-md w-full max-w-md transform transition-all duration-300 ease-in-out hover:scale-105">
                    <div className="flex justify-center mb-8">
                        <img src={logo} alt="Dressify Logo" className="w-26 h-16" />
                    </div>
                    <h2 className="text-3xl font-bold text-center text-white mb-6">Welcome to Dressify-AI</h2>
                    <p className="text-center text-gray-300 mb-8">Experience the future of fashion with AI-powered styling</p>
                    <button
                        className={`w-full py-3 px-4 bg-white text-indigo-900 rounded-lg font-semibold flex items-center justify-center transition-all duration-300 ease-in-out ${isHovered ? 'shadow-lg transform -translate-y-1' : 'shadow'}`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={handleGoogleLogin}
                    >
                        <svg viewBox="0 0 24 24" className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
                            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
                            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
                            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
                        </svg>
                        Login with Google
                    </button>
                    <p className="text-center text-gray-400 mt-6">By logging in, you agree to our Terms of Service and Privacy Policy</p>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <div className="bg-gray-900 text-white min-h-screen">
                <div className="bg-gray-800 p-4 flex justify-between items-center">
                    <h1 className="text-xl font-bold">My Dashboard</h1>
                    <div className="text-sm">
                        {user ? (
                            <>
                                <div>Welcome, {user.displayName}</div>
                                <button onClick={handleGoogleLogout} className="text-red-500">Logout</button>
                            </>
                        ) : (
                            <button onClick={handleGoogleLogin} className="text-blue-500">Login with Google</button>
                        )}
                    </div>
                    <div className="text-sm">
                        Credits Remaining: {credits}
                        Extra Credits: {extraCredits}
                        {credits === 0 && extraCredits === 0 && (
                            <button className="ml-4 bg-yellow-500 text-gray-800 font-bold py-1 px-2 rounded" onClick={() => setShowBuyCreditsPopup(true)}>
                                Buy Credits
                            </button>
                        )}
                    </div>
                    <Link to="/pricing" className="ml-4 bg-gray-500 text-white font-bold py-1 px-2 rounded">Pricing</Link>
                </div>

                <Routes>
                    <Route path="/" element={
                        <div className="flex items-center justify-center p-8">
                            <div className="flex w-full max-w-6xl">
                                {/* Input Section */}
                                <div className="w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg mr-4">
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Human Image*</label>
                                        <input type="file" onChange={(e) => handleFileUpload(e, setHumanImageFile, setHumanImagePreview)} className="w-full p-2 border border-gray-600 rounded-lg bg-white text-gray-900" accept="image/*" />
                                        {humanImagePreview && (
                                            <div className="mt-4">
                                                <img src={humanImagePreview} alt="Human Image Preview" className="rounded-lg shadow-lg" style={imagePreviewStyle} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Garment Image*</label>
                                        <input type="file" onChange={(e) => handleFileUpload(e, setGarmentImageFile, setGarmentImagePreview)} className="w-full p-2 border border-gray-600 rounded-lg bg-white text-gray-900" accept="image/*" />
                                        {garmentImagePreview && (
                                            <div className="mt-4">
                                                <img src={garmentImagePreview} alt="Garment Image Preview" className="rounded-lg shadow-lg" style={imagePreviewStyle} />
                                            </div>
                                        )}
                                    </div>

                                    {/* Cloth Type */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Cloth Type</label>
                                        <select value={clothType} onChange={(e) => setClothType(e.target.value)} className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white">
                                            <option value="Upper">Upper</option>
                                            <option value="Lower">Lower</option>
                                            <option value="Overall">Overall</option>
                                            <option value="Inner">Inner</option>
                                            <option value="Outer">Outer</option>
                                        </select>
                                    </div>

                                    {/* Additional Settings */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Image Size</label>
                                        <select value={imageSize} onChange={(e) => setImageSize(e.target.value)} className="w-full p-2 border border-gray-600 rounded-lg bg-gray-700 text-white">
                                            <option value="Default">Default</option>
                                            <option value="Square">Square</option>
                                            <option value="Square HD">Square HD</option>
                                            <option value="Portrait 3:4">Portrait 3:4</option>
                                            <option value="Portrait 9:16">Portrait 9:16</option>
                                            <option value="Landscape 4:3">Landscape 4:3</option>
                                            <option value="Landscape 16:9">Landscape 16:9</option>
                                            <option value="Custom">Custom</option>
                                        </select>
                                    </div>

                                    {imageSize === 'Custom' && (
                                        <div className="flex gap-2 mt-2">
                                            <input type="text" placeholder="Width" className="w-1/2 bg-white bg-opacity-20 rounded px-3 py-2" />
                                            <input type="text" placeholder="Height" className="w-1/2 bg-white bg-opacity-20 rounded px-3 py-2" />
                                        </div>
                                    )}

                                    {/* Inference Steps */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Num Inference Steps</label>
                                        <input type="range" min="1" max="100" value={inferenceSteps} onChange={(e) => setInferenceSteps(Number(e.target.value))} className="w-full" />
                                        <input type="number" value={inferenceSteps} onChange={(e) => setInferenceSteps(Number(e.target.value))} className="w-16 bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    {/* Guidance Scale */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Guidance Scale (CFG)</label>
                                        <input type="range" min="0" max="10" step="0.1" value={guidanceScale} onChange={(e) => setGuidanceScale(Number(e.target.value))} className="w-full" />
                                        <input type="number" value={guidanceScale} onChange={(e) => setGuidanceScale(Number(e.target.value))} className="w-16 bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    {/* Seed */}
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium mb-2">Seed</label>
                                        <input type="text" value={seed} onChange={(e) => setSeed(e.target.value)} className="w-full bg-white bg-opacity-20 rounded px-3 py-2" />
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="flex justify-end gap-4">
                                        <button className="bg-gray-600 hover:bg-gray-700 px-4 py-2 rounded" onClick={() => { setHumanImageFile(null); setGarmentImageFile(null); setHumanImagePreview(''); setGarmentImagePreview(''); }}>Reset</button>
                                        <button className={`bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded flex items-center ${isRunning ? 'loading' : ''}`} onClick={handleSubmit} disabled={isRunning}>
                                            {isRunning ? 'Processing...' : 'Run'}
                                        </button>
                                    </div>
                                </div>

                                {/* Result Section */}
                                <div className="w-1/2 bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-lg font-medium">Result</h3>
                                    {status === 'loading' && (
                                        <div className="mt-4 text-center">
                                            <ClipLoader color="#00bfff" size={50} />
                                            <p className="mt-2">Generating result...</p>
                                        </div>
                                    )}
                                    {outputImage && (
                                        <div className="mt-4">
                                            <img className="max-w-full rounded-lg shadow-lg" src={outputImage} alt="Result" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    } />

                    <Route path="/pricing" element={<PricingPage user={user} />} />
                    <Route path="/success" element={<SuccessPage />} />
                    <Route path="/cancel" element={<CancelPage />} />
                </Routes>

                {showBuyCreditsPopup && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-lg text-gray-800">
                            <h2 className="text-lg font-bold mb-4">Buy Credits</h2>
                            <SubscriptionAndPurchase user={user} setCredits={setCredits} />
                            <button onClick={() => setShowBuyCreditsPopup(false)} className="mt-4 bg-gray-500 text-white py-2 px-4 rounded">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
