import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from './firebase'; // Import your firebase auth
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import Firebase functions

const stripePromise = loadStripe('pk_live_51PrpV6GI6vk81n8VgGX3mVV3KWVSw7ZkOzrGueDavqaxyvz9P5LsfnCJWtMG6PalSJpRQevQyLwvW3p5esEgZcrw00ChlFbH7d'); // Your Stripe public key

const PricingPage = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubscription = async (priceId) => {
        setLoading(true);
        const stripe = await stripePromise;
        const functions = getFunctions(); // Initialize Firebase functions
        const createSubscription = httpsCallable(functions, 'createSubscription'); // Call Firebase function

        try {
            const response = await createSubscription({
                priceId,
                email: auth.currentUser.email, // Get the logged-in user's email from Firebase
                googleId: auth.currentUser.uid, // Use the user's Firebase UID
            });

            const { clientSecret } = response.data;
            const { error } = await stripe.confirmCardPayment(clientSecret);

            if (error) {
                setMessage('Error: ' + error.message);
            } else {
                setMessage('Subscription successful!');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Error: ' + error.message);
        }

        setLoading(false);
    };

    const handlePurchaseCredits = async (creditAmount) => {
        setLoading(true);
        const stripe = await stripePromise;
        const functions = getFunctions(); // Initialize Firebase functions
        const purchaseCredits = httpsCallable(functions, 'purchaseCredits'); // Call Firebase function

        try {
            const response = await purchaseCredits({
                creditAmount,
                email: auth.currentUser.email, // Get the logged-in user's email from Firebase
                googleId: auth.currentUser.uid, // Use the user's Firebase UID
            });

            const { clientSecret } = response.data;
            const { error } = await stripe.confirmCardPayment(clientSecret);

            if (error) {
                setMessage('Error: ' + error.message);
            } else {
                setMessage('Credits purchased successfully!');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Error: ' + error.message);
        }

        setLoading(false);
    };

    return (
        <div className="min-h-screen bg-white text-gray-800 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Choose Your Plan</h2>
                    <p className="mt-4 text-xl text-gray-600">Select the perfect plan for your AI Virtual Try-On needs</p>
                </div>

                <div className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8">
                    <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                        <h3 className="text-xl font-bold">Basic Plan</h3>
                        <p className="mt-2">$10</p>
                        <p>100 Credits</p>
                        <button
                            onClick={() => handleSubscription('price_1PxO48GI6vk81n8VDNbPRDUv')}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Subscribe Now'}
                        </button>
                    </div>

                    <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                        <h3 className="text-xl font-bold">Pro Plan</h3>
                        <p className="mt-2">$27</p>
                        <p>1000 Credits</p>
                        <button
                            onClick={() => handleSubscription('price_1PxOY5GI6vk81n8VHEdhYfwI')}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Subscribe Now'}
                        </button>
                    </div>

                    <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                        <h3 className="text-xl font-bold">Annual Plan</h3>
                        <p className="mt-2">$185</p>
                        <p>20,000 Credits</p>
                        <button
                            onClick={() => handleSubscription('price_1PxW4wGI6vk81n8VOVrsM7oY')}
                            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Subscribe Annually'}
                        </button>
                    </div>
                </div>

                <div className="mt-16 text-center">
                    <h2 className="text-2xl font-bold mb-6">Need More Credits?</h2>

                    <div className="grid grid-cols-3 gap-8">
                        <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                            <h3 className="text-xl font-bold">30 Credits</h3>
                            <p className="mt-2">$5</p>
                            <button
                                onClick={() => handlePurchaseCredits(30)}
                                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Buy Now'}
                            </button>
                        </div>

                        <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                            <h3 className="text-xl font-bold">70 Credits</h3>
                            <p className="mt-2">$10</p>
                            <button
                                onClick={() => handlePurchaseCredits(70)}
                                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Buy Now'}
                            </button>
                        </div>

                        <div className="bg-white shadow-xl transition-all duration-300 hover:scale-105 p-6 rounded-lg">
                            <h3 className="text-xl font-bold">5000 Credits</h3>
                            <p className="mt-2">$62</p>
                            <button
                                onClick={() => handlePurchaseCredits(5000)}
                                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Buy Now'}
                            </button>
                        </div>
                    </div>

                    {message && <p className="mt-4 text-red-500">{message}</p>}
                </div>
            </div>
        </div>
    );
};

export default PricingPage;
